import state from '@store/state';
import config from '@config';
import  scarfAlphabet  from '@canvas/alphabets/scarfAlphabet'

let textCtx = null;
let chars = {};
let gridPath;
let stripesInit = false;

/* ----------------------------------------
    Main dimensions
---------------------------------------- */

const dims = {
    stripeW: null,
    stripeH: null,
    letterW: 32 * 2.5,
    letterH: 20 * 2.5,
    baseX: 0,
    baseY: 0,
    gridSize: 2,
    stripeQuant: 42
};

/* ----------------------------------------
    Draw letters
---------------------------------------- */

let _drawLetter = (ctx, index) => {

    if (index < 0 || !chars[index]) return;

    // to avoid weird behavior with globalCompositeOperation, we draw background before and after
    ctx.fillStyle = chars[index].background;
    ctx.fillRect(dims.stripeW * index + dims.baseX, dims.baseY, dims.stripeW, dims.letterH);
    ctx.putImageData(chars[index].imgData, index * dims.stripeW + dims.baseX, dims.baseY);

    // Destination-over to make the bg transparent
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = chars[index].background;
    ctx.fillRect(dims.stripeW * index + dims.baseX, dims.baseY, dims.stripeW, dims.letterH);
    
    ctx.globalCompositeOperation = 'source-over';
}

/* ----------------------------------------
    Generate letters + colors
---------------------------------------- */

let _generateLetter = (ctx, textCtx, index, char, color, updateAll) => {
    if (!char || !color.second.color) return;
    let image = new Image();

    // only calculate if color changes
    if(updateAll){
        if (chars[index]) {
            if (color.first.color == chars[index].background && color.second.color == chars[index].foreground) {
                return;
            }
        }
    }

    // if char exists in images, else use space
    if (scarfAlphabet.large[char.toLowerCase()]) {
        image.src = scarfAlphabet.large[char.toLowerCase()];
    } else {
        image.src = scarfAlphabet.large[' '];
    }

    image.onload = function() {
        textCtx.clearRect(0,0,300,300)
        textCtx.fillStyle = color.second.color;
        textCtx.drawImage(image, 0, 0);
        textCtx.globalCompositeOperation = 'source-in';
        textCtx.fillRect(0,0, dims.letterW, dims.letterH);
        textCtx.globalCompositeOperation = 'source-over';
        let charImgData = textCtx.getImageData(0, 0,  dims.letterW, dims.letterH);
        chars[index] = {
            foreground: color.second.color,
            background: color.first.color,
            imgData: charImgData,
            char: char
        }

        _drawLetter(ctx, index);
    }
}

/* ----------------------------------------
    Draw edges
---------------------------------------- */

const _drawEdges = (ctx, x, y, width, height, inv) => {
    const colors = config.colors.colors.scarf;
    const unitWidth = width / 10;

    if (!inv) {
        for (let i = 0; i < colors.length; i++) {
            ctx.fillStyle = '#000000'
            ctx.fillRect(x, y, unitWidth  * 2, height);
            ctx.fillStyle = colors[i].color
            ctx.fillRect(x + (unitWidth * 2) + (unitWidth * i), y, unitWidth, height);
        }
    } else {
        for(var i = colors.length - 1; i >= 0; i--) {
            ctx.fillStyle = '#000000'
            ctx.fillRect(x + 8, y, unitWidth * 2, height);
            ctx.fillStyle = colors[i].color;
            ctx.fillRect(x + (unitWidth * i), y, unitWidth, height);
        }
    }
}

/* ----------------------------------------
    Set clip paths
---------------------------------------- */

const _setClipPaths = (ctx, shape, x, y, width, height) => {
    shape.shape = new Path2D();
    shape.points.forEach((point, i) => {
        if (i == 0) {
            shape.shape.moveTo(point[0] + x, point[1] + y);
        } else {
            shape.shape.lineTo(point[0] + x, point[1] + y);
        }
    })
    shape.shape.lineTo(shape.points[0][0] + x, shape.points[0][1] + y);
    ctx.clip(shape.shape)
}

/* ----------------------------------------
    Draw grid
---------------------------------------- */

const _initGrid = (width, height, unitSize) => {
    gridPath = new Path2D();
    for (let j = 0; j < height / unitSize; j++) {   
        for (let i = 0; i < width / unitSize; i++) {
            let shift = j % 2 == 0 ? 0 : unitSize;
            gridPath.rect(shift + 0 + i * unitSize * 2, 0 + j * unitSize, unitSize, unitSize);
        }
    }
}

/* ----------------------------------------
    Draw grid
---------------------------------------- */
 
const _grid = (ctx, colorChoices, x, y, width, height, unitSize, ind) => {
    !gridPath && _initGrid(width, height, unitSize);
    
    if (colorChoices[ind].first.color) {
        ctx.fillStyle = colorChoices[ind].first.color;
        ctx.fillRect(x ,y ,width ,height);
    }

    if (colorChoices[ind].second.color) {
        ctx.fillStyle = colorChoices[ind].second.color;
        ctx.save();
        ctx.translate(x, y)
        ctx.fill(gridPath);
        ctx.restore();
    }
}

/* ----------------------------------------
    Draw Stripe
---------------------------------------- */

const _drawStripe = (ctx, colorChoices, shape, ind, x, y, width, height, updateAll) => {
    if (!colorChoices) return;
    if(updateAll){
        if (!shape.selected) return;
    }

    ctx.strokeRect(0,0, 10,10)
    ctx.fillStyle = colorChoices[ind].first.color;
    _grid(ctx, colorChoices, x, y + dims.letterH, dims.stripeW, dims.stripeH, dims.gridSize, ind);
}

/* ----------------------------------------
    Reset all
---------------------------------------- */

const resetAllStripes = () => {
    stripesInit = false;
}

/* ----------------------------------------
    Update preview
---------------------------------------- */

const scarfPreview = (ctx, canvas, shapes, colorChoices, x, y, w, h, imprint) => {
    dims.baseX = x;
    dims.baseY = y;
    dims.stripeW = w;
    dims.stripeH = h;
    ctx.lineWidth = 3;

    _drawEdges(ctx, dims.baseX - 10, dims.baseY, 10, dims.stripeH);
    _drawEdges(ctx, dims.baseX + dims.stripeW * dims.stripeQuant, dims.baseY, 10, dims.stripeH, true);
    
    shapes.forEach((shape, j) => { 
        let transX = dims.baseX + shape.origin[0];
        let transY = dims.baseY + shape.origin[1];

        ctx.save();
        
        _setClipPaths(ctx, shape, transX, transY, dims.stripeW, dims.stripeH)
        _drawStripe(ctx, colorChoices, shape, j, transX, transY, dims.stripeW, dims.stripeH, stripesInit);
        _generateLetter(ctx, textCtx, j, imprint[j], { first: colorChoices[j].first, second: colorChoices[j].second }, stripesInit);

        ctx.restore();
    });

    stripesInit = true;
};

/* ----------------------------------------
    Init
---------------------------------------- */

const initScarfText = () => {
    const scarfTextCanvas = document.createElement('canvas'); 
    scarfTextCanvas.id = "scarfTextCanvas"; 
    scarfTextCanvas.width = 1200;
    scarfTextCanvas.height = dims.letterH; 
    document.body.appendChild(scarfTextCanvas);
    textCtx = scarfTextCanvas.getContext("2d");

    return scarfTextCanvas;
}

/* ----------------------------------------
    Export Module
---------------------------------------- */

export {
    initScarfText,
    scarfPreview,
    resetAllStripes
};
