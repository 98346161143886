
















/* ----------------------------------------
        Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';

/* ----------------------------------------
        Component
---------------------------------------- */

@Component({
    components: {},
    props: ['stripe', 'colors'],
    data() {
        return {
            filenames: {
                sock: [ 'black-green','black-blue','green-blue','pink-black','pink-green','pink-blue','grey-black','grey-green','grey-pink','grey-blue','yellow-black','yellow-green','yellow-pink','yellow-blue','yellow-grey',],
                scarf: [ 'green-black','green-greengrey','green-blue','pink-black','pink-green','pink-greengrey','pink-blue','greengrey-black','blue-black','blue-greengrey','grey-black','grey-green','grey-pink','grey-greengrey','grey-blue','grey-red','red-black','red-green','red-pink','red-greengrey','red-blue','yellow-black','yellow-green','yellow-pink','yellow-greengrey','yellow-blue','yellow-grey','yellow-red' ]
            }
        }
    },
    computed: {
        ...mapState(['sessionData'])
    },
    watch: {
        stripe: function (val) {
            // PUSH TIMER LEFT

            //close
            if(val == undefined) {
                this.setTimerRight(0);
            }
            //small
            else if (this.sessionData.product_type == 'scarf' &&  this.sessionData.size == 'L') {
                this.setTimerRight(140);
            }
            //large
            else if(this.sessionData.product_type == 'sock') {
                this.setTimerRight(260);
            }
            //normal
            else {
                this.setTimerRight(200);
            } 
        },
    },
    methods: {
        ...mapMutations(['setTimerRight']),
        rmSpaces(str) {
            return str.replace(/\s/g, '').replace(/\-/g, '');
        },
        searchFile() {
            const filenames = this.filenames[this.sessionData.product_type]
            let found = -1;
            
            for (let i = 0; i < filenames.length; i++) {
                const splitName = filenames[i].split('-');
                let firstColor = this.rmSpaces(this.colors[this.stripe].first.name.en.toLowerCase());
                let secondColor = this.rmSpaces(this.colors[this.stripe].second.name.en.toLowerCase());

                if (
                    splitName[0] == firstColor &&
                    splitName[1] == secondColor
                ) {
                    found = i;
                }

                // search inverse
                if (
                    splitName[1] == firstColor &&
                    splitName[0] == secondColor
                ) {
                    found = i;
                }
            }

            if (found !== -1) {
                return filenames[found]
            } else {
                return null
            }
        },
        imgSrc(name) {
            return `${window.location.origin}/assets/images/textile_preview/${this.sessionData.product_type}/${name}.jpg`;
        }
    },
})

/* ----------------------------------------
        Export
---------------------------------------- */

export default class TextilePreview extends Vue {};

