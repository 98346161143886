/** ----------------------------------------
    Helper functions
---------------------------------------- */

/** ----------------------------------------
    Delete element from dom
---------------------------------------- */

const removeFromDom = (el) => {
    el.parentNode.removeChild(el);
}

/** ----------------------------------------
    Delete all elements with a certain class
---------------------------------------- */

const obliterateClass = (className) => {
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach(el => {
        removeFromDom(el);
    })
}

/** ----------------------------------------
    ImageData to image
---------------------------------------- */

const imgDataToImg = (imagedata) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = imagedata.width;
    canvas.height = imagedata.height;
    ctx.putImageData(imagedata, 0, 0);

    var image = new Image();
    image.src = canvas.toDataURL();
    return image;
}

/** ----------------------------------------
    Degrees to radians
---------------------------------------- */

const degreesToRadians = (deg) => {
    var pi = Math.PI;
    return deg * (pi/180);
}

export { removeFromDom, obliterateClass, imgDataToImg, degreesToRadians }