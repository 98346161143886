var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scarf",class:{ preview: _vm.editorState == 0, editing: _vm.editorState == 1 }},[_c('TextilePreview',{attrs:{"stripe":_vm.selectedStripe[_vm.selectedStripe.length - 1],"colors":_vm.colorChoices}}),_c('div',{staticClass:"scarf__canvas-wrap",on:{"scroll":_vm.updateScrollables}},[_c('div',{staticClass:"scarf__scroll-arrow scarf__scroll-arrow--left",class:{
                inactive: !_vm.scrollableLeft || _vm.editorState == 0
            },on:{"click":function($event){return _vm.scroll('l')}}},[_c('ArrowBackSvg')],1),_c('div',{staticClass:"scarf__scroll-arrow scarf__scroll-arrow--right",class:{
                inactive: !_vm.scrollableRight || _vm.editorState == 0
            },on:{"click":function($event){return _vm.scroll('r')}}},[_c('ArrowForwardSvg')],1),_c('img',{staticClass:"scarf__photo",class:("scarf__photo--" + (_vm.sessionData.size)),attrs:{"src":_vm.imgSrc()}}),_vm._l((_vm.colorChoices),function(color,i){return (_vm.shapes[0])?_c('div',{key:'stripe-status'+i,staticClass:"scarf__stripe-status",class:{
                selected: _vm.selectedStripe.indexOf(i) >= 0
            },style:({
                'left': ((_vm.shapes[i].origin[0] + _vm.scarfLeft) + "px"),
                'top': ((_vm.shapes[i].origin[1] + _vm.scarfTop) + "px"),
                'height': (_vm.scarfPreviewH + "px")
            })},[(i < 42)?_c('div',{staticClass:"scarf__stripe-status--inner"},[_c('EditSvg')],1):_vm._e()]):_vm._e()}),_c('canvas',{directives:[{name:"long-press",rawName:"v-long-press",value:(300),expression:"300"}],staticClass:"scarf__canvas",attrs:{"id":"scarf-canvas"},on:{"long-press-start":_vm.onLongPressStart,"long-press-stop":_vm.onLongPressStop,"touchstart":function($event){return _vm.setMouseCoords($event)},"mousedown":function($event){return _vm.setMouseCoords($event)},"click":function($event){return _vm.clicked($event)}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }