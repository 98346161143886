import state from '@store/state';
// We use the scarf alphbet for the preview, as it is larger.
import scarfAlphabet from '@canvas/alphabets/scarfAlphabet';
import { dimsMain, dimsMod } from '@canvas/sockShapes';
import { imgDataToImg, degreesToRadians } from './helper';
import { obliterateClass } from './helper';

let textCtx = null;
let previewLettersInit = false;
let previewLettersImgs = [];
let chars = {};
let sockImg;

/* ----------------------------------------
    Draw zigzag
---------------------------------------- */

const _zig = (ctx, x, y, width, height, segments, color, lineWidth) => {
    const startX = x - 30;
    const startY = y - 30;
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(startX, startY);
  
    for (var n = 0; n < segments; n++) {
        var x = startX + ((n + 1) * width / segments);
        var y;
        
        if (n % 2 == 0) {
            y = startY + height;
        } else {
            y = startY;
        }
        ctx.lineTo(x, y);
    }

    ctx.stroke();
};

/* ----------------------------------------
    Trim text
---------------------------------------- */

// Just a small portion of the text fits on the sockPreview, so we have to trim the str.
// We remove a portion in the beginning and another in the end.

const _trimText = (str, maxLen) => {
    if (str.length <= maxLen) return str;

    const trim = (str.length - maxLen) / 2; 
    let result = str;

    // if even
    if (Number.isInteger(trim)) {
        result = result.substring(0, result.length - trim);
        result = result.substring(trim)
    }
    // if odd
    else {
        const trim1 = Math.floor(trim)
        const trim2 = Math.ceil(trim)
        result = result.substring(0, result.length - trim2);
        result = result.substring(trim1)
    }

    return result;
}

/* ----------------------------------------
    Set clip paths
---------------------------------------- */

// The zig zag patterns are clipped to adopt the shape of the stripe

const _setClipPaths = (ctx, shape, x, y, width, height) => {
    shape.shape = new Path2D();

    shape.points.forEach((point, i) => {
        if (i == 0) {
            shape.shape.moveTo(point[0] + x, point[1] + y);
        } else {
            shape.shape.lineTo(point[0] + x, point[1] + y);
        }
    })

    shape.shape.lineTo(shape.points[0][0] + x, shape.points[0][1] + y);
    ctx.clip(shape.shape)
}

/* ----------------------------------------
    Draw outline
---------------------------------------- */

const _drawOutline = (ctx, shape, x, y, width, height) => {
    shape.shape = new Path2D();

    shape.points.forEach((point, i) => {
        if (i == 0) {
            shape.shape.moveTo(point[0] + x, point[1] + y);
        } else {
            shape.shape.lineTo(point[0] + x, point[1] + y);
        }
    })

    shape.shape.lineTo(shape.points[0][0] + x, shape.points[0][1] + y);

    shape.selected ? ctx.strokeStyle = 'black' : ctx.strokeStyle = 'rgba(0,0,0,0)';
    if (state.editorState == 1) {
        ctx.lineWidth = 3;
        ctx.stroke(shape.shape);
    }
}

/* ----------------------------------------
    Draw Stripe
---------------------------------------- */

const _drawStripe = (ctx, colorChoices, ind, x, y, width, height) => {
    if (!colorChoices) return;

    // 30 smaller "sub-stripes" for each stripe
    const unitH = height / 30;

    if (colorChoices[ind].first.color) {
        ctx.fillStyle = colorChoices[ind].first.color;
        
        // stripes
        for (let i = 0; i < 32; i++) {
            _zig(ctx, x, y + unitH * i * 2, width + 100, 3, 100, colorChoices[ind].first.color, unitH / 2 + 0.6);
        }
    }

    if (colorChoices[ind].second.color) {
        ctx.fillStyle = colorChoices[ind].second.color;

        // stripes
        for (let i = 0; i < 32; i++) {
            _zig(ctx, x, y + unitH * i * 2 + unitH, width + 100, 3, 100, colorChoices[ind].second.color, unitH / 2 + 0.6);
        }
    }
}

/* ----------------------------------------
    Draw letters
---------------------------------------- */

const _drawLetter = (ctx, index, fullText, activeSize) => {
    if (index < 0 || !chars[index]) return;

    // space between letters
    let spacing = 32;
    let middle = (fullText.length * spacing) / 2;
    let offsetTextX = 100;
    let offsetTextY = -60;
    let baseX = dimsMain.baseX + dimsMod[activeSize].textX + offsetTextX;
    let baseY = dimsMain.baseY + dimsMod[activeSize].textY + offsetTextY;

    if (previewLettersImgs.length < fullText.length) {
        previewLettersImgs.push(imgDataToImg(chars[index].imgData));
    }

    ctx.globalCompositeOperation = 'color-burn';
    ctx.drawImage(previewLettersImgs[index], (index * spacing)- middle + baseX, baseY);
    ctx.globalCompositeOperation = 'source-over';
}

/* ----------------------------------------
    Generate letters + colors
---------------------------------------- */

const _generateLetter = (ctx, textCtx, index, fullText, char, activeSize) => {
    if (!char) return;

    // first time generate all, rest of times just draw
    if (!previewLettersInit) {
        let image = new Image();
    
        // if char exists in images
        if (scarfAlphabet.large[char.toLowerCase()]) {
            image.src = scarfAlphabet.large[char.toLowerCase()];
        } else {
            image.src = scarfAlphabet.large[' '];
        }
    
        image.onload = function() {
            textCtx.clearRect(0,0,300,300)
            textCtx.fillStyle = '#474543';
            textCtx.drawImage(image, -24, 0);
            textCtx.globalCompositeOperation = 'source-in';
            textCtx.fillRect(0,0, dimsMain.letterW, dimsMain.letterH);
            textCtx.globalCompositeOperation = 'source-over';
            let charImgData = textCtx.getImageData(0, 0,  dimsMain.letterW, dimsMain.letterH);
            chars[index] = {
                imgData: charImgData,
                char: char
            }
    
            _drawLetter(ctx, index, fullText, activeSize);
        }
    } else {
        _drawLetter(ctx, index, fullText, activeSize);
    }
}

/* ----------------------------------------
    Draw photo
---------------------------------------- */

// We draw the photo in the canvas so we can apply the global composite operation effect properly

const sockPhoto = (ctx) => {
    sockImg = document.querySelector('.sock__photo');
    const x = sockImg.offsetLeft - 497; // 497 sidebar width
    const y = sockImg.offsetTop;
    const w = sockImg.clientWidth;
    const h = sockImg.clientHeight;
    ctx.drawImage(sockImg, x, y, w, h);
}

/* ----------------------------------------
    MAIN
---------------------------------------- */

const sockPreview = (ctx, canvas, outlineCtx, shapes, sockLength, sockSize, colorChoices, w, h, imprint) => {
    const activeSize = sockLength + sockSize;
    const mainW = w;
    const mainH = h;
    const trimmedImprint = _trimText(imprint, 11)
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    outlineCtx.clearRect(0, 0, canvas.width, canvas.height);
    sockPhoto(ctx);
    
    for (let i = 0; i < trimmedImprint.length; i++) {
        _generateLetter(ctx, textCtx, i, trimmedImprint, trimmedImprint[i], activeSize);   
    }
    
    previewLettersInit = true;

    //////////// PREVIEW ////////////
    shapes.forEach((shape, j) => {
        let transX = dimsMain.baseX + dimsMod[activeSize].baseX + shape.origin[0];
        let transY = dimsMain.baseY + dimsMod[activeSize].baseY + shape.origin[1];

        ctx.save();
        ctx.translate( transX + mainW / 2, transY + mainH / 2 );
        ctx.rotate(degreesToRadians(shape.rotation));
        ctx.translate( -transX -mainW / 2, -transY -mainH / 2 );

        outlineCtx.save();
        outlineCtx.translate( transX + mainW / 2, transY + mainH / 2 );
        outlineCtx.rotate(degreesToRadians(shape.rotation));
        outlineCtx.translate( -transX -mainW / 2, -transY -mainH / 2 );
   
        _setClipPaths(ctx, shape, transX, transY, mainW, mainH);
        ctx.globalCompositeOperation = 'color-burn';
        _drawStripe(ctx, colorChoices, j, transX, transY, mainW, mainH);  
        ctx.globalCompositeOperation = 'source-over'; 

        _drawOutline(outlineCtx, shape, transX, transY, mainW, mainH) 

        ctx.restore();
        outlineCtx.restore(); 
    })
};

/* ----------------------------------------
    Init sock preview text
---------------------------------------- */

const initSockPreviewText = () => {
    chars = {};
    previewLettersImgs = [];
    previewLettersInit = false;
    obliterateClass('sockTextPreviewCanvas');

    const sockTextPreviewCanvas = document.createElement('canvas'); 
    sockTextPreviewCanvas.id = "sockTextPreviewCanvas"; 
    sockTextPreviewCanvas.classList.add("sockTextPreviewCanvas"); 
    sockTextPreviewCanvas.width = 300;
    sockTextPreviewCanvas.height = 100; 
    document.body.appendChild(sockTextPreviewCanvas);
    textCtx = sockTextPreviewCanvas.getContext("2d");

    return sockTextPreviewCanvas;
}

/* ----------------------------------------
    Export Module
---------------------------------------- */

export {
    initSockPreviewText,
    sockPreview,
};
