export default {
    en: {
        first: 'FIRST THREAD',
        second: 'SECOND THREAD',
        yellow: 'Yellow',
        grey: 'Grey',
        pink: 'Pink',
        black: 'Black',
        green: 'Green',
        blue: 'Blue',
        save: 'SAVE',
        confirm: 'Are you sure?',
        confirmText: 'This is it. The moment of truth. All or nothing. You can no longer change your design after this',
        confirmYes: 'YES, CONTINUE',
        confirmNo: 'NO, GO BACK',
        reset: 'Warning',
        resetText: 'Are you sure you want to reset your design?',
        resetYes: 'YES, CONTINUE',
        resetNo: 'NO, GO BACK',
        instructions: 'Select a stripe by tapping on the circle. You can select multiple stripes by long-pressing.',
        edit: 'Edit design',
        choose: 'Choose a section to continue',
        back: 'Go back?',
        backText: 'Are you sure you want to go back? Your design will be lost.',
        backYes: 'Yes',
        backNo: 'No',
        explanation: 'Always mix two colored threads into a new color'
    },
    nl: {
        first: 'EERSTE DRAAD',
        second: 'TWEEDE DRAAD',
        yellow: 'Geel',
        grey: 'Grijs',
        pink: 'Roze',
        black: 'Zwart',
        green: 'Groen',
        blue: 'Blauw',
        save: 'OPSLAAN',
        confirm: 'Weet je het zeker?',
        confirmText: 'Dit is ‘m dan. Het moment van de waarheid. Erop of eronder. Je kunt je design hierna niet meer aanpassen.',
        confirmYes: 'JA, DOORGAAN',
        confirmNo: 'NEE, TERUG',
        reset: 'Let op!',
        resetText: 'Weet je zeker dat je helemaal opnieuw wilt beginnen met je design?',
        resetYes: 'Ja, wis mijn design',
        resetNo: 'Nee, ga terug',
        instructions: 'Selecteer een streep door op de cirkel te drukken. Je kan meerdere strepen selecteren door deze langer ingedrukt te houden.',
        edit: 'Pas design aan',
        choose: 'Kies een deel om door te gaan',
        back: 'Go back?',
        backText: 'Are you sure you want to go back? Your design will be lost.',
        backYes: 'Ja',
        backNo: 'Nee',
        explanation: 'Mix steeds twee gekleurde draden tot een nieuwe kleur'
    }
};
