








































































/* =================================================

    *---------------*
    │ SCARF FILES 🧣 │
    *---------------*
    │
    ├-Scarf.vue
    │   └- Main file. Initialize everything + Dom elements
    ├-scarf.js
    │   └- Generates scarf preview + text
    ├-scarfBmp.js
    │   └- Generates scarf bmp
    └-scarfAlphabet.js
        └- Defines preview+bmp text images

    * Unlike the sock, the scarf doesn't require:
        - A scarfText.js file, because the text gets generated together with the
          preview and has to be colored according to color choices from the user.
        - A scarfShapes.js file, because the shapes are just rectangles.

================================================= */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { scarfBmp, initBmp, getScarfBmps } from '@canvas/scarfBmp';
import { initScarfText, scarfPreview, resetAllStripes } from '@canvas/scarf';
import { obliterateClass } from '@canvas/helper';
import TextilePreview from '@part/elements/TextilePreview.vue';
import EditSvg from '@svg/Edit.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import ArrowBackSvg from '@svg/ArrowBack.vue';
import LongPress from 'vue-directive-long-press';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        TextilePreview,
        EditSvg,
        ArrowForwardSvg,
        ArrowBackSvg
    },
    directives: {
        'long-press': LongPress
    },
    data() {
        return {
            canvas: null,
            ctx: null,
            bmpCanvas: null,
            bmpCtx: null,
            textCanvas: null,
            textCtx: null,
            scarfW: 32,
            scarfH: 200,
            scarfPreviewW: 32 * 2.5,
            scarfPreviewH: 200 * 2.5,
            scarfTop: 290,
            scarfLeft: 200,
            shapes: [],
            canvasWrap: null,
            scrollAmount: 600,
            scrollableLeft: false,
            scrollableRight: true,
            longPressing: false,
            init: false,
            mouse: {
                offsetX: null,
                offsetY: null
            }
        }
    },
    computed: {
        ...mapState(['colorChoices', 'selectedStripe', 'triggerUpdateCanvas', 'triggerResetColors', 'sessionData', 'editorState']),
        stripeCount() {
            return this.colorChoices.length;
        },
        // Check if any color has been chosen
        colorsChosen() {
            for (let i = 0; i < this.colorChoices.length; i++) {
                if(!this.colorChoices[i].first.color) return false;
                if(!this.colorChoices[i].second.color) return false;
            }
            return true;
        }
    },
    watch: {
        // Update canvas
        triggerUpdateCanvas: function (val) {
            this.updateCanvas();
        },
        // Reset colors
        triggerResetColors: function (val) {
            resetAllStripes();
            this.updateCanvas();
        },
        // Reset scroll pos if not editing
        editorState: function (val) {
            this.editorState == 0 && this.resetScroll();
        },
    },
    methods: {
        // Check if click is in scarf stripe
        isInPath(event, shape) {
            return this.ctx.isPointInPath(shape, event.offsetX, event.offsetY);
        },

        // Detect mouse click or touch events
        setMouseCoords(event) {
            // touch
            if (event.touches) {
                let scrollPos = this.canvasWrap.scrollLeft;
                let sidebarW = document.querySelector('.sidebar').offsetWidth;
                this.mouse.offsetX = event.touches[0].pageX - sidebarW + scrollPos;
                this.mouse.offsetY = event.touches[0].pageY;
            }
            // click
            else {
                this.mouse.offsetX = event.offsetX;
                this.mouse.offsetY = event.offsetY;
            }
        },
        
        // Deselect all stripes
        deselectAll() {
            this.shapes.forEach((shape, i) => {
                this.shapes[i].selected = false;
            });
        },

        // Scarf stripe click
        clicked(event) {
            if (this.longPressing) return;
            let noneClicked = true;

            this.shapes.forEach((shape, i) => {
                if (this.isInPath(event, shape.shape)) {
                    let currentState = this.shapes[i].selected;
                    this.deselectAll();
                    this.shapes[i].selected = !currentState;
                    this.shapes[i].selected ? this.selectStripe(i) : this.selectStripe(-1);
                    this.updateCanvas();
                    noneClicked = false;
                }
            });

            if (noneClicked) {
                this.selectStripe(-1);
                this.deselectAll();
                this.updateCanvas();
            }
        },

        // Long press / multi select
        onLongPressStart() {
            this.longPressing = true;

            this.shapes.forEach((shape, i) => {
                if (this.isInPath(this.mouse, shape.shape)) {
                    let currentState = this.shapes[i].selected;
                    this.shapes[i].selected = !currentState;
                    this.multiselectStripe(i);
                    this.updateCanvas();
                }
            });

            this.updateCanvas();

        },

        // End long press
        onLongPressStop() {
            setTimeout(() => {
                this.longPressing = false;
            }, 100);
        },

        // Update canvas colors, etc and update BMP
        updateCanvas() {
            scarfPreview(
                this.ctx,
                this.canvas,
                this.shapes,
                this.colorChoices,
                this.scarfLeft, // X
                this.scarfTop, // Y
                this.scarfPreviewW, // W
                this.scarfPreviewH, // H
                this.sessionData.imprint
            );

            scarfBmp(this.bmpCtx,
                this.bmpCanvas,
                this.shapes,
                this.scarfH,
                this.colorChoices,
                this.sessionData.imprint,
                this.init
            );
            
            if (!this.colorsChosen) return;

            setTimeout(() => {
                this.savePreview(this.canvas.toDataURL('image/png'));
                this.saveBmp(getScarfBmps(this.bmpCtx, this.scarfH));
            }, 400);
        },

        // Get correct image path
        imgSrc() {
            return `${window.location.origin}/assets/images/${this.sessionData.product_type}/${this.sessionData.size}.jpg`;
        },

        // Reset scroll
        resetScroll() {
            this.canvasWrap.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': 0
            });
            
            setTimeout(() => {
                this.updateScrollables();
            }, 500);
        },

        // Scroll scarf left or right
        scroll(dir) {
            let scrollPos = this.canvasWrap.scrollLeft;
            let scrollTo = dir == 'l' ? this.scrollAmount * -1 : this.scrollAmount;

            this.canvasWrap.scrollTo({
                'behavior': 'smooth',
                'left': scrollPos + scrollTo,
                'top': 0
            });
            
            setTimeout(() => {
                this.updateScrollables();
            }, 500);
        },

        // Check if possible to scroll left or right
        updateScrollables() {
            let scrollPos = this.canvasWrap.scrollLeft;
            scrollPos >= 2000 ? (this.scrollableRight = false) : (this.scrollableRight = true);
            scrollPos >= 40 ? (this.scrollableLeft = true) : (this.scrollableLeft = false); 
        }
    },
    mounted() {
        // Remove existing canvases, reseting bmps
        obliterateClass('imageDataCanvas');
        obliterateClass('bmpCanvas');
        obliterateClass('bmpTextCanvas');

        // Initialize text. It is generated before the preview
        this.canvasWrap = document.querySelector('.scarf__canvas-wrap');
        initScarfText();
        this.sessionData.size == 'L' && (this.scarfPreviewH = 296 * 2.5) && (this.scarfH = 296) && (this.scarfTop = 170);

        setTimeout(() => {

            // Init bmp canvas
            const bmpCanvas = initBmp(this.colorChoices, this.scarfH);
            this.bmpCanvas = bmpCanvas;
            this.bmpCtx = this.bmpCanvas.getContext('2d');

            // Add shapes corresponding to size and length of the sock
            for (let i = 0; i < this.colorChoices.length; i++) {
                this.shapes.push({
                    selected: false,
                    origin: [i * this.scarfPreviewW, 0],
                    rotation: 0,
                    points: [
                        [0, 0],
                        [this.scarfPreviewW, 0],
                        [this.scarfPreviewW, this.scarfPreviewH],
                        [0,this.scarfPreviewH],
                    ],
                    shape: null
                });
            }

            this.canvas = document.getElementById('scarf-canvas');
            this.ctx = this.canvas.getContext('2d');
            this.canvas.width = (this.scarfPreviewW * 42) + (this.scarfLeft * 2);
            this.canvas.height = 1080;
            resetAllStripes();
            this.updateCanvas();
            this.init = true;
        }, 800);
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Scarf extends Vue {};

