/* ----------------------------------------
    Imports
---------------------------------------- */

import { obliterateClass } from './helper';

/* ----------------------------------------
    Draw top line
---------------------------------------- */

const _drawTop = (ctx) => {
    ctx.save();
    ctx.translate(0,0)
    ctx.fillStyle = '#00a800';
    ctx.fillRect(0, 0, 120, 1);
    ctx.fillRect(0, 0, 1, 2);
    ctx.restore();
}

/* ----------------------------------------
    Draw Stripe
---------------------------------------- */

const _drawStripe = (ctx, colorChoices, stripeNo, width, height) => {
    if (!colorChoices) return;

    ctx.save();
    ctx.translate(0, height * stripeNo);

    if (colorChoices.first.sockCode) {
        ctx.fillStyle = colorChoices.first.sockCode;

        // seams
        ctx.fillRect(0, 2, 1, 11);
        ctx.fillRect(width -1, 1, 1, 11);

        // stripes
        for (let ind = 0; ind < 11; ind++) {
            ctx.fillRect(1, 1 + ind * 2, width - 2, 1);
        }
    }

    if (colorChoices.second.sockCode) {
        ctx.fillStyle = colorChoices.second.sockCode;

        // seams
        ctx.fillRect(0, 13, 1, 11);
        ctx.fillRect(width - 1, 12, 1, 11);

        // stripes
        for (let ind = 0; ind < 11; ind++) {
            ctx.fillRect(1, 2 + ind * 2, width - 2, 1);
        }
    }
    ctx.restore();
}

/* ----------------------------------------
    Draw all
---------------------------------------- */

const sockBmp = (ctx, canvas, shapes, colorChoices) => {
    const width = 120;
    const height = 22;
    
    _drawTop(ctx);

    shapes.forEach((shape, j) => {
        _drawStripe(ctx, colorChoices[j], j, width, height);
    })

    let imageData = canvas.getContext("2d").getImageData(0, 0, canvas.clientWidth, canvas.clientHeight);
    return imageData;
};

/* ----------------------------------------
    Init
---------------------------------------- */

const initBmp = (colorChoices) => {
    obliterateClass('bmpCanvas')

    const bmpCanvas = document.createElement('canvas'); 
    bmpCanvas.id = "bmpCanvas"; 
    bmpCanvas.classList.add("bmpCanvas"); 
    bmpCanvas.width = 120;
    bmpCanvas.height = 1 + (colorChoices.length * 22); 
    document.body.appendChild(bmpCanvas);
    return bmpCanvas;
}

/* ----------------------------------------
    Export Module
---------------------------------------- */

export {
  sockBmp,
  initBmp
};
