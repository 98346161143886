























































































/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState, mapMutations } from 'vuex';
import Sidebar from '@part/elements/Sidebar.vue';
import Sock from '@part/features/Sock.vue';
import Scarf from '@part/features/Scarf.vue';
import lang from '@lang/sidebar.js';
import ArrowBackSvg from '@svg/ArrowBack.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        ArrowBackSvg,
        Sidebar,
        Sock,
        Scarf
    },
    props: [],
    data() {
        return {
            // either resetWarning, confirmSave or back
            overlayType: 'confirmSave',
        };
    },
    computed: {
        ...mapState(['sessionData', 'colorChoices']),
        lang() {
            return lang[this.activeLang()];
        },
    },
    methods: {
        ...mapMutations(['initColorChoices', 'setOverlayContent', 'setTimerRight']),
        setAndDisplayOverlay(overlayType) {
            if (this.sessionData.product_type === 'sock')
                this.combSock(this.colorChoices);

            this.setUpdateCanvas();
            this.overlayType = overlayType;
            setTimeout(() => {
                let overlayContent = document.querySelector('.overlay-content');
                this.setOverlayContent(overlayContent.innerHTML);

                setTimeout(() => {
                    this.triggerOverlay(true);
                    this.setTimerRight(0);
                }, 400)
            },100);
        }
    },
    mounted() {
        this.initColorChoices();
        window.oncontextmenu = (e) => {
            e.preventDefault();
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Design extends Vue {}
