


















































/* =================================================

    *---------------*
    │ SOCK FILES 🧦 │
    *---------------*
    │
    ├-Sock.vue
    │   └- Main file. Initialize everything + Dom elements
    ├-sock.js
    │   └- Generates sock preview
    ├-sockText.js
    │   └- Generates sock preview text
    ├-sockShapes.js
    │   └- Defines sock shapes
    ├-sockBmp.js
    │   └- Generates sock bmp
    └-sockAlphabet.js
        └- Defines preview+bmp text images

================================================= */

/* ----------------------------------------
        Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { sockBmp, initBmp } from '@canvas/sockBmp';
import { initSockText, sockText } from '@canvas/sockText';
import { initSockPreviewText, sockPreview } from '@canvas/sock';
import { sockShapes, dimsMain, dimsMod } from '@canvas/sockShapes';
import TextilePreview from '@part/elements/TextilePreview.vue';
import EditSvg from '@svg/Edit.vue';
import LongPress from 'vue-directive-long-press';
import { degreesToRadians } from '@canvas/helper';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        TextilePreview,
        EditSvg
    },
    directives: {
        'long-press': LongPress
    },
    data() {
        return {
            canvas: null,
            ctx: null,
            bmpCanvas: null,
            bmpCtx: null,
            textCanvas: null,
            textCtx: null,
            outlineCanvas: null,
            outlineCtx: null,
            sockShapesCopy: sockShapes,
            shapes: [                    
            ],
            sockReady: false,
            longPressing: false,
            mouse: {
                offsetX: null,
                offsetY: null
            }
        }
    },
    computed: {
        ...mapState(['colorChoices', 'selectedStripe', 'triggerUpdateCanvas', 'sessionData', 'editorState']),
        stripeCount() {
            return this.colorChoices.length;
        },
        // DimsMod define the base coordinates for each sock size, since they all differ
        dimsMod() {
            return dimsMod
        },
        // editIconAdj defines the base coordinates for each sock length, since they all differ
        editIconAdj() {
            if (this.sessionData['length'] == 1) return {x: 0, y: 0};
            if (this.sessionData['length'] == 2) return {x: 18, y: 4};
            if (this.sessionData['length'] == 3) return {x: 18, y: 8};
        }

    },
    watch: {
        triggerUpdateCanvas: function (val) {
            this.updateCanvas();
        },
    },
    methods: {
        // This function returns the correct size sock image
        imgSrc(type, format) {
            return `${window.location.origin}/assets/images/${this.sessionData.product_type}/${this.sessionData['length']}-${this.sessionData.size}${type}.${format}`;
        },

        // Detect mouse click or touch events
        setMouseCoords(event) {
            // touch
            if (event.touches) {
                let sidebarW = document.querySelector('.sidebar').offsetWidth;
                this.mouse.offsetX = event.touches[0].pageX - sidebarW;
                this.mouse.offsetY = event.touches[0].pageY;
            }
            // click
            else {
                this.mouse.offsetX = event.offsetX;
                this.mouse.offsetY = event.offsetY;
            }
        },

        // Check if sock is actually clicked
        // The context has to be transformed and rotated to correspond with the sock shapes
        isInPath(event, shape, shapeIndex) {
            const mainW = 220;
            const mainH = 100;
            const activeSize = this.sessionData['length'] + this.sessionData['size'];
            const shapeData = this.sockShapesCopy[`${ this.sessionData.length }${ this.sessionData.size }`]
            const origin = { x: shapeData[shapeIndex].origin[0], y: shapeData[shapeIndex].origin[1] };
            const rot = shapeData[shapeIndex].rotation;
            const transX = dimsMain.baseX + dimsMod[activeSize].baseX + origin.x;
            const transY = dimsMain.baseY + dimsMod[activeSize].baseY + origin.y;

            this.ctx.save();
            this.ctx.translate( transX + mainW / 2, (transY + mainH / 2) );
            this.ctx.rotate(degreesToRadians(rot));
            this.ctx.translate( -transX - mainW / 2, (-transY -mainH / 2)  );

            let inPath = this.ctx.isPointInPath(shape, event.offsetX, event.offsetY);
            this.ctx.restore(); 

            if (inPath) {
                return true;
            } else {
                return false;
            }
        },
        deselectAll() {
            this.shapes.forEach((shape, i) => {
                this.shapes[i].selected = false;
            })
        },

        // Sock stripe click
        clicked(event) {
            if (this.longPressing) return;
            let noneClicked = true;

            this.shapes.forEach((shape, i) => {
                if (this.isInPath(event, shape.shape, i)) {
                    let currentState = this.shapes[i].selected;
                    this.deselectAll();
                    this.shapes[i].selected = !currentState;
                    this.shapes[i].selected ? this.selectStripe(i) : this.selectStripe(-1);
                    this.combSock(this.colorChoices);
                    this.updateCanvas();
                    noneClicked = false;
                }
            })

            if (noneClicked) {
                this.selectStripe(-1);
                this.deselectAll();
                this.updateCanvas();
            }
        },

        // Long press / multi select
        onLongPressStart(event) {
            this.longPressing = true;

            this.shapes.forEach((shape, i) => {
                if (this.isInPath(this.mouse, shape.shape, i)) {
                    let currentState = this.shapes[i].selected;
                    this.shapes[i].selected = !currentState;
                    this.multiselectStripe(i);
                    this.combSock(this.colorChoices);
                    this.updateCanvas();
                }
            })

            this.updateCanvas();
        },

        // End long press
        onLongPressStop(event) {
            setTimeout(() => {
                this.longPressing = false;
            }, 100);
        },

        // Update canvas colors, etc and update BMP
        updateCanvas() {
            if (!this.sessionData.order_id) return;

            sockPreview(
                this.ctx,
                this.canvas,
                this.outlineCtx,
                this.shapes,
                this.sessionData['length'],
                this.sessionData['size'],
                this.colorChoices,
                220,
                100,
                this.sessionData.imprint
            );

            sockBmp(
                this.bmpCtx,
                this.bmpCanvas,
                this.shapes,
                this.colorChoices
            );

            setTimeout(() => {
                this.savePreview(this.canvas.toDataURL('image/png'));
                this.saveBmp(this.bmpCanvas.toDataURL("image/png"));
            }, 400);
        }
    },
    mounted() {
        setTimeout(() => {
            // Initialize BMP
            const bmpCanvas = initBmp(this.colorChoices);
            initSockPreviewText();
            this.bmpCanvas = bmpCanvas;
            this.bmpCtx = this.bmpCanvas.getContext("2d");

            // Initialize sock text canvas
            const textCanvas = initSockText();
            this.textCanvas = textCanvas;
            this.textCtx = this.textCanvas.getContext("2d");
            sockText(this.textCtx, this.sessionData.imprint);

            // Save text bmp (this doesn't change during design process)
            setTimeout(() => {
                this.saveImprintBmp(this.textCanvas.toDataURL("image/png"));
            }, 2000);

            // Add shapes corresponding to size and length of the sock
            for (let i = 0; i < this.colorChoices.length; i++) {
                this.shapes.push(
                    {
                        selected: false,
                        origin: sockShapes[this.sessionData['length'] + this.sessionData['size']][i].origin,
                        rotation: sockShapes[this.sessionData['length'] + this.sessionData['size']][i].rotation,
                        points: sockShapes[this.sessionData['length'] + this.sessionData['size']][i].points,
                        shape: null
                    }
                )
            }

            // Define all canvases
            this.canvas = document.getElementById("sock-canvas");
            this.ctx = this.canvas.getContext("2d");
            this.outlineCanvas = document.getElementById("sock-canvas-outlines");
            this.outlineCtx = this.outlineCanvas.getContext("2d");
            
            // Canvas sizes
            this.canvas.width = 1422;
            this.canvas.height = 1080;
            this.outlineCanvas.width = 1422;
            this.outlineCanvas.height = 1080;
            this.updateCanvas();

            setTimeout(() => {
                this.sockReady = true;
            }, 1200);
        }, 100);
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Sock extends Vue {};

