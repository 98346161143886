import sockAlphabet from '@canvas/alphabets/sockAlphabet'
import { obliterateClass } from './helper';

let colorized = 0;
let charsImageData = [];

const _colorizeLetter = (ctx, text, colors) => {
    let lowerText = text.toLowerCase();
    let image = new Image();

    if (sockAlphabet[lowerText[colorized]]) {
        image.src = sockAlphabet[lowerText[colorized]];
    } else {
        image.src = sockAlphabet[' '];
    }

    image.onload = function() {
        ctx.clearRect(0,0,300,300)
        ctx.fillStyle = colors[colorized];
        ctx.drawImage(image, 0, 0);
        ctx.globalCompositeOperation = 'source-in';
        ctx.fillRect(0,0,10,43);
        ctx.globalCompositeOperation = 'source-over';
        let charImgData = ctx.getImageData(0, 0, 10, 43);
        charsImageData.push(charImgData)
        colorized++;

        if(colorized === text.length) {
            placeChars(ctx, text);
        } else {
            _colorizeLetter(ctx, text, colors)
        }
    };
    return true;
}

const placeChars = (ctx, text) => {
    const textWidth = text.length * 10;
    const bottom = 31;
    ctx.clearRect(0, 0 ,300,300);

    charsImageData.forEach((char, i) => {
        ctx.putImageData(char, i * 10 + (60 - textWidth / 2),  bottom - 6 );
    });

    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#00a800';
    ctx.fillRect(0,0,120 * scl,43 * scl);
    ctx.globalCompositeOperation = 'source-over';
}

/* ----------------------------------------
    Draw all
---------------------------------------- */

const scl = 1;

const sockText = (ctx, text) => {
    const textWidth = text.length * 10;
    const tempColors = ['#dcdcdc'];
    ctx.scale(scl,scl)
    _colorizeLetter(ctx, text, tempColors);
    ctx.globalCompositeOperation = 'source-over';
};

/* ----------------------------------------
    Init
---------------------------------------- */

const initSockText = () => {
    colorized = 0;
    charsImageData = [];
    obliterateClass('sockTextCanvas');

    let sockTextCanvas = document.createElement('canvas'); 
    sockTextCanvas.id = "sockTextCanvas"; 
    sockTextCanvas.classList.add("sockTextCanvas");
    sockTextCanvas.width = 120 * scl;
    sockTextCanvas.height = 43 * scl; 
    document.body.appendChild(sockTextCanvas);
    return sockTextCanvas;
}

/* ----------------------------------------
    Export Module
---------------------------------------- */

export { sockText, initSockText }
