/* ----------------------------------------
    Global sock dimensions
---------------------------------------- */
const dimsMain = {
    stripeW: 196,
    stripeH: 56,
    baseX: 598,
    baseY: 190,
    letterW: 40,
    letterH: 20 * 2.5,
};

/* ----------------------------------------
    Size per size
    Adjust dimensions per size. Sizes 1, 2 and 3
---------------------------------------- */
const sizeAdj = [
    1.2,
    0.95,
    0.75
];

/* ----------------------------------------
    Adjust position of socks and text per size + length
---------------------------------------- */

const dimsMod = {

    // Size 1
    '1S': {
        baseX: -18,
        baseY: 144,
        textX: 8,
        textY: 130
    },
    '1M': {
        baseX: -48,
        baseY: 93,
        textX: -22,
        textY: 83
    },
    '1L': {
        baseX: -60,
        baseY: 83,
        textX: -30,
        textY: 73
    },
    '1XL': {
        baseX: -60,
        baseY: 45,
        textX: -30,
        textY: 35
    },
    // size 2
    '2S': {
        baseX: 0,
        baseY: 14,
        textX: 0,
        textY: 14
    },
    '2M': {
        baseX: -50,
        baseY: -40,
        textX: -50,
        textY: -40
    },
    '2L': {
        baseX: -32,
        baseY: -50,
        textX: -32,
        textY: -50
    },
    '2XL': {
        baseX: -36,
        baseY: -58,
        textX: -32,
        textY: -58
    },
    // size 3
    '3S': {
        baseX: 0,
        baseY: -10,
        textX: 0,
        textY: -10
    },
    '3M': {
        baseX: 0,
        baseY: -30,
        textX: 0,
        textY: -30
    },
    '3L': {
        baseX: 0,
        baseY: -24,
        textX: 0,
        textY: -24
    },
    '3XL': {
        baseX: 0,
        baseY: -44,
        textX: 0,
        textY: -44
    }
};

/* ----------------------------------------
    // Adjust actual shapes of the stripes individually
---------------------------------------- */

const sockShapes = {

    /** ----------------------------------------
    Length 1 / Size S /////// 8 stripes
    ---------------------------------------- */

    '1S': [
        {
            origin: [0,0],
            rotation: 0,
            points: [
                [20,0],
                [dimsMain.stripeW + 40,0],
                [dimsMain.stripeW + 51, (dimsMain.stripeH * sizeAdj[0]) / 3],
                [dimsMain.stripeW + 52, dimsMain.stripeH * sizeAdj[0]],
                [14, dimsMain.stripeH * sizeAdj[0]],
            ]
        },{
            origin: [0, dimsMain.stripeH * sizeAdj[0]],
            rotation: 0,
            points: [
                [14,0],
                [dimsMain.stripeW + 52,0],
                [dimsMain.stripeW + 65, dimsMain.stripeH * sizeAdj[0] + 10],
                [dimsMain.stripeW / 2 + 34, dimsMain.stripeH * sizeAdj[0] + 28],
                [2, dimsMain.stripeH * sizeAdj[0]],
            ]
        },{
            origin: [52, (dimsMain.stripeH * sizeAdj[0]) * 2 + 50],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 + 23, -28],
                [dimsMain.stripeW + 43 , 21],
                [dimsMain.stripeW + 43, (dimsMain.stripeH * sizeAdj[0])],
                [8, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [90, (dimsMain.stripeH * sizeAdj[0]) * 3 + 37],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 38, 0],
                [dimsMain.stripeW + 39, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [122, (dimsMain.stripeH * sizeAdj[0]) * 4 + 28],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 40, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [156, (dimsMain.stripeH * sizeAdj[0]) * 5 + 19],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 40, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [190, (dimsMain.stripeH * sizeAdj[0]) * 6 + 10],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 33, (dimsMain.stripeH * sizeAdj[0])],
                [7, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [230, (dimsMain.stripeH * sizeAdj[0]) * 7 - 2],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 26, 0],
                [dimsMain.stripeW + 16, (dimsMain.stripeH * sizeAdj[0]) / 2],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[0])],
                [20, (dimsMain.stripeH * sizeAdj[0])],
                [5, (dimsMain.stripeH * sizeAdj[0])/2],
            ]
        } 
    ],

    /** ----------------------------------------
    Length 1 / Size M /////// 9 stripes
    ---------------------------------------- */

    '1M': [
        {
            origin: [0,0],
            rotation: 0,
            points: [
                [20,-3],
                [dimsMain.stripeW + 40,0],
                [dimsMain.stripeW + 51, (dimsMain.stripeH * sizeAdj[0]) / 3],
                [dimsMain.stripeW + 52, dimsMain.stripeH * sizeAdj[0]],
                [14, dimsMain.stripeH * sizeAdj[0]],
            ]
        },{
            origin: [0, dimsMain.stripeH * sizeAdj[0]],
            rotation: 0,
            points: [
                [14,0],
                [dimsMain.stripeW + 52,0],
                [dimsMain.stripeW + 65, dimsMain.stripeH * sizeAdj[0] + 10],
                [dimsMain.stripeW / 2 + 34, dimsMain.stripeH * sizeAdj[0] + 28],
                [2, dimsMain.stripeH * sizeAdj[0]],
            ]
        },{
            origin: [52, (dimsMain.stripeH * sizeAdj[0]) * 2 + 50],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 + 26, -28],
                [dimsMain.stripeW + 45 , 21],
                [dimsMain.stripeW + 43, (dimsMain.stripeH * sizeAdj[0])],
                [8, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [90, (dimsMain.stripeH * sizeAdj[0]) * 3 + 37],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 38, 0],
                [dimsMain.stripeW + 39, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [122, (dimsMain.stripeH * sizeAdj[0]) * 4 + 28],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 40, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [156, (dimsMain.stripeH * sizeAdj[0]) * 5 + 19],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 40, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [190, (dimsMain.stripeH * sizeAdj[0]) * 6 + 10],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 38, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [224, (dimsMain.stripeH * sizeAdj[0]) * 7 + 1],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 38, 0],
                [dimsMain.stripeW + 34, (dimsMain.stripeH * sizeAdj[0]) / 2],
                [dimsMain.stripeW + 25, (dimsMain.stripeH * sizeAdj[0])],
                [5, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])/2],
            ]
        },{
            origin: [262, (dimsMain.stripeH * sizeAdj[0]) * 8 - 10],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 20, 0],
                [dimsMain.stripeW + 3, (dimsMain.stripeH * sizeAdj[0]) / 2],
                [dimsMain.stripeW - 26, (dimsMain.stripeH * sizeAdj[0])],
                [28, (dimsMain.stripeH * sizeAdj[0])],
                [10, (dimsMain.stripeH * sizeAdj[0])/2],
            ]
        } 
    ],

    /** ----------------------------------------
    Length 1 / Size L /////// 10 stripes
    ---------------------------------------- */

    '1L': [
        {
            origin: [0,0],
            rotation: 0,
            points: [
                [20,0],
                [dimsMain.stripeW + 44,0],
                [dimsMain.stripeW + 53, (dimsMain.stripeH * sizeAdj[0]) / 3],
                [dimsMain.stripeW + 56, dimsMain.stripeH * sizeAdj[0]],
                [14, dimsMain.stripeH * sizeAdj[0]],
            ]
        },{
            origin: [0, dimsMain.stripeH * sizeAdj[0]],
            rotation: 0,
            points: [
                [14,0],
                [dimsMain.stripeW + 56,0],
                [dimsMain.stripeW + 65, dimsMain.stripeH * sizeAdj[0] + 10],
                [dimsMain.stripeW / 2 + 34, dimsMain.stripeH * sizeAdj[0] + 28],
                [2, dimsMain.stripeH * sizeAdj[0]],
            ]
        },{
            origin: [52, (dimsMain.stripeH * sizeAdj[0]) * 2 + 50],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 + 23, -28],
                [dimsMain.stripeW + 43 , 21],
                [dimsMain.stripeW + 43, (dimsMain.stripeH * sizeAdj[0])],
                [8, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [90, (dimsMain.stripeH * sizeAdj[0]) * 3 + 37],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 38, 0],
                [dimsMain.stripeW + 39, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [122, (dimsMain.stripeH * sizeAdj[0]) * 4 + 28],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 40, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [156, (dimsMain.stripeH * sizeAdj[0]) * 5 + 19],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 40, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [190, (dimsMain.stripeH * sizeAdj[0]) * 6 + 10],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 38, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [224, (dimsMain.stripeH * sizeAdj[0]) * 7 + 1],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 38, 0],
                [dimsMain.stripeW + 38, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [256, (dimsMain.stripeH * sizeAdj[0]) * 8 - 7],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 38, 0],
                [dimsMain.stripeW + 34, (dimsMain.stripeH * sizeAdj[0]) / 2],
                [dimsMain.stripeW + 25, (dimsMain.stripeH * sizeAdj[0])],
                [12, (dimsMain.stripeH * sizeAdj[0])],
                [6, (dimsMain.stripeH * sizeAdj[0])/2],
            ]
        },{
            origin: [290, (dimsMain.stripeH * sizeAdj[0]) * 9 - 16],
            rotation: -30,
            points: [
                [12,0],
                [dimsMain.stripeW + 24, 0],
                [dimsMain.stripeW + 10, (dimsMain.stripeH * sizeAdj[0]) / 2],
                [dimsMain.stripeW - 14, (dimsMain.stripeH * sizeAdj[0])],
                [49, (dimsMain.stripeH * sizeAdj[0])],
                [24, (dimsMain.stripeH * sizeAdj[0])/2],
            ]
        }
    ],

    /** ----------------------------------------
    Length 1 / Size XL /////// 11 stripes
    ---------------------------------------- */

    '1XL': [
        {
            origin: [0,0],
            rotation: 0,
            points: [
                [5,0],
                [dimsMain.stripeW + 50,0],
                [dimsMain.stripeW + 56, dimsMain.stripeH * sizeAdj[0]],
                [-3, dimsMain.stripeH * sizeAdj[0]],
            ]
        },{
            origin: [0, dimsMain.stripeH * sizeAdj[0]],
            rotation: 0,
            points: [
                [-3,0],
                [dimsMain.stripeW + 56,0],
                [dimsMain.stripeW + 70, dimsMain.stripeH * sizeAdj[0] - 10],
                [dimsMain.stripeW / 2 + 34, dimsMain.stripeH * sizeAdj[0] + 28],
                [-10, dimsMain.stripeH * sizeAdj[0] - 20],
            ]
        },{
            origin: [52, (dimsMain.stripeH * sizeAdj[0]) * 2 + 50],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 + 23, -28],
                [dimsMain.stripeW + 59 , 5],
                [dimsMain.stripeW + 54, (dimsMain.stripeH * sizeAdj[0])],
                [6, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [90, (dimsMain.stripeH * sizeAdj[0]) * 3 + 37],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 48, 0],
                [dimsMain.stripeW + 47, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [122, (dimsMain.stripeH * sizeAdj[0]) * 4 + 28],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 47, 0],
                [dimsMain.stripeW + 47, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [156, (dimsMain.stripeH * sizeAdj[0]) * 5 + 19],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 47, 0],
                [dimsMain.stripeW + 47, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [190, (dimsMain.stripeH * sizeAdj[0]) * 6 + 10],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 47, 0],
                [dimsMain.stripeW + 47, (dimsMain.stripeH * sizeAdj[0])],
                [0, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [224, (dimsMain.stripeH * sizeAdj[0]) * 7 + 1],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW + 47, 0],
                [dimsMain.stripeW + 44, (dimsMain.stripeH * sizeAdj[0])],
                [6, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [256, (dimsMain.stripeH * sizeAdj[0]) * 8 - 7],
            rotation: -30,
            points: [
                [8,0],
                [dimsMain.stripeW + 46, 0],
                [dimsMain.stripeW + 40, (dimsMain.stripeH * sizeAdj[0])],
                [16, (dimsMain.stripeH * sizeAdj[0])],
            ]
        },{
            origin: [290, (dimsMain.stripeH * sizeAdj[0]) * 9 - 16],
            rotation: -30,
            points: [
                [16,0],
                [dimsMain.stripeW + 40, 0],
                [dimsMain.stripeW + 35, (dimsMain.stripeH * sizeAdj[0]) / 2],
                [dimsMain.stripeW + 25, (dimsMain.stripeH * sizeAdj[0])],
                [38, (dimsMain.stripeH * sizeAdj[0])],
                [24, (dimsMain.stripeH * sizeAdj[0])/2],
            ]
        },{
            origin: [324, (dimsMain.stripeH * sizeAdj[0]) * 10 - 25],
            rotation: -30,
            points: [
                [38,0],
                [dimsMain.stripeW + 24, 0],
                [dimsMain.stripeW + 12, (dimsMain.stripeH * sizeAdj[0]) / 2],
                [dimsMain.stripeW - 10, (dimsMain.stripeH * sizeAdj[0])],
                [75, (dimsMain.stripeH * sizeAdj[0])],
                [53, (dimsMain.stripeH * sizeAdj[0])/2],
            ]
        }
    ],

    /** ----------------------------------------
    Length 2 / Size S /////// 14 stripes
    ---------------------------------------- */

    '2S': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,34],
                [15,0],
                [dimsMain.stripeW - 15,0],
                [dimsMain.stripeW, 34],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 8, (dimsMain.stripeH * sizeAdj[1]) - 6],
                [dimsMain.stripeW / 2 + 5, (dimsMain.stripeH * sizeAdj[1]) + 15],
                [0, (dimsMain.stripeH * sizeAdj[1]) - 20],
            ]
        },{
            origin: [50, 8 * (dimsMain.stripeH * sizeAdj[1]) + 20],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 - 9, - 26],
                [dimsMain.stripeW - 8, 5],
                [dimsMain.stripeW - 6, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [76, 9 * (dimsMain.stripeH * sizeAdj[1]) + 13],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [102, 10 * (dimsMain.stripeH * sizeAdj[1]) + 6],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 10 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [128, 11 * (dimsMain.stripeH * sizeAdj[1]) - 1],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 10 ,0],
                [dimsMain.stripeW - 14 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [154, 12 * (dimsMain.stripeH * sizeAdj[1]) + - 8],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 14 ,0],
                [dimsMain.stripeW - 18 , (dimsMain.stripeH * sizeAdj[1]) / 2],
                [dimsMain.stripeW - 26 , (dimsMain.stripeH * sizeAdj[1])],
                [6, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [180, 13 * (dimsMain.stripeH * sizeAdj[1]) - 15],
            rotation: -30,
            points: [
                [6,0],
                [dimsMain.stripeW - 26 ,0],
                [dimsMain.stripeW - 38 , (dimsMain.stripeH * sizeAdj[1]) / 2],
                [dimsMain.stripeW - 57 , (dimsMain.stripeH * sizeAdj[1])],
                [34, (dimsMain.stripeH * sizeAdj[1])],
                [14, (dimsMain.stripeH * sizeAdj[1]) / 2],
            ]
        }
    ],

    /** ----------------------------------------
    Length 2 / Size M /////// 15 stripes
    ---------------------------------------- */

    '2M': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0, 20],
                [14,0],
                [dimsMain.stripeW - 14,0],
                [dimsMain.stripeW, 20],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-2, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-2,0],
                [dimsMain.stripeW + 4, 0],
                [dimsMain.stripeW + 12, (dimsMain.stripeH * sizeAdj[1]) - 7],
                [dimsMain.stripeW / 2 + 5, (dimsMain.stripeH * sizeAdj[1]) + 15],
                [-6, (dimsMain.stripeH * sizeAdj[1]) - 20],
            ]
        },{
            origin: [50, 8 * (dimsMain.stripeH * sizeAdj[1]) + 20],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 - 9, - 26],
                [dimsMain.stripeW - 6, 6],
                [dimsMain.stripeW - 6, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [76, 9 * (dimsMain.stripeH * sizeAdj[1]) + 13],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [102, 10 * (dimsMain.stripeH * sizeAdj[1]) + 6],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [128, 11 * (dimsMain.stripeH * sizeAdj[1]) - 1],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [154, 12 * (dimsMain.stripeH * sizeAdj[1]) + - 8],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [180, 13 * (dimsMain.stripeH * sizeAdj[1]) + - 15],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [206, 14 * (dimsMain.stripeH * sizeAdj[1]) - 22],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 10 , (dimsMain.stripeH * sizeAdj[1])],
                [10, (dimsMain.stripeH * sizeAdj[1])],
            ]
        }
    ],

    /** ----------------------------------------
    Length 2 / Size L /////// 16 stripes
    ---------------------------------------- */

    '2L': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4, 10],
                [3,0],
                [dimsMain.stripeW - 7 + 4,0],
                [dimsMain.stripeW + 4, 10],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 4, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW + 4,0],
                [dimsMain.stripeW + 8, (dimsMain.stripeH * sizeAdj[1]) - 12],
                [dimsMain.stripeW / 2 + 9, (dimsMain.stripeH * sizeAdj[1]) + 15],
                [-4, (dimsMain.stripeH * sizeAdj[1]) - 20],
            ]
        },{
            origin: [50, 8 * (dimsMain.stripeH * sizeAdj[1]) + 20],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 - 9, - 26],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [76, 9 * (dimsMain.stripeH * sizeAdj[1]) + 13],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [102, 10 * (dimsMain.stripeH * sizeAdj[1]) + 6],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [128, 11 * (dimsMain.stripeH * sizeAdj[1]) - 1],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [154, 12 * (dimsMain.stripeH * sizeAdj[1]) + - 8],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [180, 13 * (dimsMain.stripeH * sizeAdj[1]) + - 15],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [206, 14 * (dimsMain.stripeH * sizeAdj[1]) + - 22],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 10 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [232, 15 * (dimsMain.stripeH * sizeAdj[1]) - 29],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 10 , 0],
                [dimsMain.stripeW - 18 , (dimsMain.stripeH * sizeAdj[1]) / 2],
                [dimsMain.stripeW - 32 , (dimsMain.stripeH * sizeAdj[1])],
                [28, (dimsMain.stripeH * sizeAdj[1])],
                [12, (dimsMain.stripeH * sizeAdj[1]) / 2],
            ]
        }
    ],

    /** ----------------------------------------
    Length 2 / Size L /////// 17 stripes
    ---------------------------------------- */

    '2XL': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW, 0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [-4, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [-4,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW + 16, (dimsMain.stripeH * sizeAdj[1]) - 12],
                [dimsMain.stripeW / 2 + 9, (dimsMain.stripeH * sizeAdj[1]) + 15],
                [-12, (dimsMain.stripeH * sizeAdj[1]) - 20],
            ]
        },{
            origin: [50, 8 * (dimsMain.stripeH * sizeAdj[1]) + 20],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 - 9, - 26],
                [dimsMain.stripeW, 5],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [76, 9 * (dimsMain.stripeH * sizeAdj[1]) + 13],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW, 0],
                [dimsMain.stripeW - 3, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [102, 10 * (dimsMain.stripeH * sizeAdj[1]) + 6],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 3 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [128, 11 * (dimsMain.stripeH * sizeAdj[1]) - 1],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 7 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [154, 12 * (dimsMain.stripeH * sizeAdj[1]) + - 8],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 7 ,0],
                [dimsMain.stripeW - 8 , (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [180, 13 * (dimsMain.stripeH * sizeAdj[1]) + - 15],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 8, 0],
                [dimsMain.stripeW - 8, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [206, 14 * (dimsMain.stripeH * sizeAdj[1]) + - 22],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 8, 0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[1])],
                [0, (dimsMain.stripeH * sizeAdj[1])],
            ]
        },{
            origin: [232, 15 * (dimsMain.stripeH * sizeAdj[1]) - 29],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 12 , 0],
                [dimsMain.stripeW - 15 , (dimsMain.stripeH * sizeAdj[1]) / 2],
                [dimsMain.stripeW - 20 , (dimsMain.stripeH * sizeAdj[1])],
                [8, (dimsMain.stripeH * sizeAdj[1])],
                [2, (dimsMain.stripeH * sizeAdj[1]) / 2],
            ]
        },{
            origin: [258, 16 * (dimsMain.stripeH * sizeAdj[1]) - 36],
            rotation: -30,
            points: [
                [8,0],
                [dimsMain.stripeW - 20 , 0],
                [dimsMain.stripeW - 30 , (dimsMain.stripeH * sizeAdj[1]) / 2],
                [dimsMain.stripeW - 52 , (dimsMain.stripeH * sizeAdj[1])],
                [28, (dimsMain.stripeH * sizeAdj[1])],
                [12, (dimsMain.stripeH * sizeAdj[1]) / 2],
            ]
        }
    ],

    /** ----------------------------------------
    Length 3 / Size S /////// 17 stripes
    ---------------------------------------- */

    '3S': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0, 20],
                [14,0],
                [dimsMain.stripeW - 14,0],
                [dimsMain.stripeW, 20],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 8 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 9 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 10 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW + 8, (dimsMain.stripeH * sizeAdj[2]) - 6],
                [dimsMain.stripeW / 2, (dimsMain.stripeH * sizeAdj[2]) + 15],
                [0, (dimsMain.stripeH * sizeAdj[2]) - 20],
            ]
        },{
            origin: [50, 11 * (dimsMain.stripeH * sizeAdj[2]) + 20],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 - 14, - 28],
                [dimsMain.stripeW - 10, 6],
                [dimsMain.stripeW - 16, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [71, 12 * (dimsMain.stripeH * sizeAdj[2]) + 14],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 16 ,0],
                [dimsMain.stripeW - 14 , (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [92, 13 * (dimsMain.stripeH * sizeAdj[2]) + 8],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 14 ,0],
                [dimsMain.stripeW - 10 , (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [113, 14 * (dimsMain.stripeH * sizeAdj[2]) + 2],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 10 ,0],
                [dimsMain.stripeW - 6, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [134, 15 * (dimsMain.stripeH * sizeAdj[2]) + - 4],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [155, 16 * (dimsMain.stripeH * sizeAdj[2]) + - 10],
            rotation: -30,
            points: [
                [4,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 11 , (dimsMain.stripeH * sizeAdj[2])],
                [16, (dimsMain.stripeH * sizeAdj[2])],
            ]
        }
    ],

    /** ----------------------------------------
    Length 3 / Size M /////// 18 stripes
    ---------------------------------------- */

    '3M': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0, 20],
                [14,0],
                [dimsMain.stripeW - 14,0],
                [dimsMain.stripeW, 20],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 8 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 9 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 10 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW + 8, (dimsMain.stripeH * sizeAdj[2]) - 6],
                [dimsMain.stripeW / 2 + 5, (dimsMain.stripeH * sizeAdj[2]) + 15],
                [0, (dimsMain.stripeH * sizeAdj[2]) - 20],
            ]
        },{
            origin: [50, 11 * (dimsMain.stripeH * sizeAdj[2]) + 20],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 - 9, - 26],
                [dimsMain.stripeW - 8, 4],
                [dimsMain.stripeW - 6, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [71, 12 * (dimsMain.stripeH * sizeAdj[2]) + 14],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[2])],
                [2, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [92, 13 * (dimsMain.stripeH * sizeAdj[2]) + 8],
            rotation: -30,
            points: [
                [2,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [113, 14 * (dimsMain.stripeH * sizeAdj[2]) + 2],
            rotation: -30,
            points: [
                [4,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [134, 15 * (dimsMain.stripeH * sizeAdj[2]) + - 4],
            rotation: -30,
            points: [
                [4,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[2])],
                [8, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [155, 16 * (dimsMain.stripeH * sizeAdj[2]) + - 10],
            rotation: -30,
            points: [
                [8,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[2])],
                [13, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [176, 17 * (dimsMain.stripeH * sizeAdj[2]) + - 16],
            rotation: -30,
            points: [
                [13,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 14 , (dimsMain.stripeH * sizeAdj[2])],
                [18, (dimsMain.stripeH * sizeAdj[2])],
            ]
        }
    ],

   /** ----------------------------------------
    Length 3 / Size L /////// 19 stripes
    ---------------------------------------- */
    
    '3L': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [10, 20],
                [16,0],
                [dimsMain.stripeW - 14,0],
                [dimsMain.stripeW - 6, 20],
                [dimsMain.stripeW - 4, (dimsMain.stripeH * sizeAdj[2])],
                [8, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [8,0],
                [dimsMain.stripeW - 4,0],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [6, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [6,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [4,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [2, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [2,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 8 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 9 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW,0],
                [dimsMain.stripeW + 2, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 10 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW + 2,0],
                [dimsMain.stripeW + 8, (dimsMain.stripeH * sizeAdj[2]) - 12],
                [dimsMain.stripeW / 2 + 5, (dimsMain.stripeH * sizeAdj[2]) + 15],
                [0, (dimsMain.stripeH * sizeAdj[2]) - 10],
            ]
        },{
            origin: [50 - 6, 11 * (dimsMain.stripeH * sizeAdj[2]) + 20],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW / 2 - 9, - 26],
                [dimsMain.stripeW - 2, 4],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [71 - 6, 12 * (dimsMain.stripeH * sizeAdj[2]) + 14],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [92 - 6, 13 * (dimsMain.stripeH * sizeAdj[2]) + 8],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [113 - 6, 14 * (dimsMain.stripeH * sizeAdj[2]) + 2],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [134 - 6, 15 * (dimsMain.stripeH * sizeAdj[2]) + - 4],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 4 , (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [155 - 6, 16 * (dimsMain.stripeH * sizeAdj[2]) + - 10],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 4 ,0],
                [dimsMain.stripeW - 6 , (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [176 - 6, 17 * (dimsMain.stripeH * sizeAdj[2]) + - 16],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [10, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [197 - 6, 18 * (dimsMain.stripeH * sizeAdj[2]) + - 22],
            rotation: -30,
            points: [
                [10,0],
                [dimsMain.stripeW - 12 ,0],
                [dimsMain.stripeW - 26 , (dimsMain.stripeH * sizeAdj[2])],
                [24, (dimsMain.stripeH * sizeAdj[2])],
            ]
        }
    ],
    /** ----------------------------------------
    Length 3 / Size XL /////// 20 stripes
    ---------------------------------------- */
    
    '3XL': [
        {
            origin: [0, 0 * (dimsMain.stripeH * sizeAdj[1])],
            rotation: 0,
            points: [
                [10, 20],
                [14,0],
                [dimsMain.stripeW - 18, 0],
                [dimsMain.stripeW - 14, 20],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [6, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 1 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [6,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 2 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [4,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 3 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [4,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 4 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [4,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 5 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [4,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 6 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [4,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [4, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 7 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [4,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [3, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 8 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [3,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW - 12, (dimsMain.stripeH * sizeAdj[2])],
                [2, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 9 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [2,0],
                [dimsMain.stripeW - 12,0],
                [dimsMain.stripeW -8, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [0, 10 * (dimsMain.stripeH * sizeAdj[2])],
            rotation: 0,
            points: [
                [0,0],
                [dimsMain.stripeW - 8,0],
                [dimsMain.stripeW, (dimsMain.stripeH * sizeAdj[2]) - 12],
                [dimsMain.stripeW / 2 + 5, (dimsMain.stripeH * sizeAdj[2]) + 15],
                [0, (dimsMain.stripeH * sizeAdj[2]) - 10],
            ]
        },{
            origin: [50 - 16, 11 * (dimsMain.stripeH * sizeAdj[2]) + 20],
            rotation: -30,
            points: [
                [-4,7],
                [dimsMain.stripeW / 2, - 19],
                [dimsMain.stripeW, 4],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [0, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [71 - 16, 12 * (dimsMain.stripeH * sizeAdj[2]) + 14],
            rotation: -30,
            points: [
                [0,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [6, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [92 - 16, 13 * (dimsMain.stripeH * sizeAdj[2]) + 8],
            rotation: -30,
            points: [
                [6,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [8, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [113 - 16, 14 * (dimsMain.stripeH * sizeAdj[2]) + 2],
            rotation: -30,
            points: [
                [8,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 2, (dimsMain.stripeH * sizeAdj[2])],
                [10, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [134 - 16, 15 * (dimsMain.stripeH * sizeAdj[2]) + - 4],
            rotation: -30,
            points: [
                [10,0],
                [dimsMain.stripeW - 2,0],
                [dimsMain.stripeW - 4 , (dimsMain.stripeH * sizeAdj[2])],
                [12, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [155 - 16, 16 * (dimsMain.stripeH * sizeAdj[2]) + - 10],
            rotation: -30,
            points: [
                [12,0],
                [dimsMain.stripeW - 4 ,0],
                [dimsMain.stripeW - 4, (dimsMain.stripeH * sizeAdj[2])],
                [14, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [176 - 16, 17 * (dimsMain.stripeH * sizeAdj[2]) + - 16],
            rotation: -30,
            points: [
                [14,0],
                [dimsMain.stripeW - 4 ,0],
                [dimsMain.stripeW - 6, (dimsMain.stripeH * sizeAdj[2])],
                [16, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [197 - 16, 18 * (dimsMain.stripeH * sizeAdj[2]) + - 22],
            rotation: -30,
            points: [
                [16,0],
                [dimsMain.stripeW - 6 ,0],
                [dimsMain.stripeW - 8 , (dimsMain.stripeH * sizeAdj[2])],
                [22, (dimsMain.stripeH * sizeAdj[2])],
            ]
        },{
            origin: [218 - 16, 19 * (dimsMain.stripeH * sizeAdj[2]) + - 28],
            rotation: -30,
            points: [
                [22,0],
                [dimsMain.stripeW - 8 ,0],
                [dimsMain.stripeW - 16 , (dimsMain.stripeH * sizeAdj[2])],
                [32, (dimsMain.stripeH * sizeAdj[2])],
            ]
        }
    ]
};

export { sockShapes, dimsMod, dimsMain };
