





















































































/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ColorChoice from '@part/elements/ColorChoice.vue';
import lang from '@lang/sidebar.js';
import CrossSvg from '@svg/Cross.vue';
import ResetSvg from '@svg/Reset.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        ColorChoice,
        CrossSvg,
        ResetSvg,
        ArrowForwardSvg
    },
    data() {
        return {
            tooltipShown: false
        }
    },
    computed: {
        ...mapState(['sessionData', 'colorChoices', 'selectedStripe', 'bmp', 'imprintBmp', 'editorState']),
        lastSelectedStripe() {
            return this.selectedStripe[this.selectedStripe.length - 1];
        },
        lang() {
            return lang[this.activeLang()];
        },
        submittable() {
            let submittable = true;
            this.colorChoices.forEach(choice => {
                !choice.first.color && (submittable = false);
                !choice.second.color && (submittable = false);
            });
            return submittable;
        },
        menuPos() {
            const menuWidth = 31.2;
            let pos = 0;
            this.editorState >= 1  && (pos++);
            this.selectedStripe.length > 0  && (pos++);
            return menuWidth * pos;
        }
    },
    methods: {
        toggleChoices(state) {
            this.saveEditorState(state);
            this.selectStripe(-1);
            this.setUpdateCanvas();

            if (state === 1 && !this.tooltipShown) {
                this.tooltipShown = true;
                this.triggerTooltip(this.lang.instructions, 1000, 120, 30000)
            }
        }
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Timer extends Vue {};

