
































/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import ArrowForwardSvg from '@svg/ArrowForward.vue';

/* ----------------------------------------
        Component
---------------------------------------- */

@Component({
    components: {
        ArrowForwardSvg
    },
    props: ['color', 'colorName' ,'chosen', 'textColor', 'inactive']
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Timer extends Vue {};

