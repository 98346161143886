import config from '@config';
import  scarfAlphabet  from '@canvas/alphabets/scarfAlphabet';
import { obliterateClass } from './helper';

const colors = config.colors.colors.scarfCombinations;
let bmpTextCanvas = null;
let bmpTextCtx = null;
let chars = {};
const dims = {
    stripeW: 32,
    stripeQuant: 42,
    letterH: 20
}

//search
const searchCombi = (foreground, background) => {
    for (let i = 0; i < colors.length; i++) {
        if (background === colors[i].bg) {
            if (foreground === colors[i].fg) {
                return {
                    mix: colors[i].mix,
                    fgCode: colors[i].fgCode,
                    bgCode: colors[i].bgCode
                }
            }
        }

        // inverse
        if (background === colors[i].fg) {
            if (foreground === colors[i].bg) {
                return {
                    mix: colors[i].mix,
                    fgCode: colors[i].bgCode,
                    bgCode: colors[i].fgCode
                }
            }
        }
    }
    return null;
}

/* ----------------------------------------
    Draw letters
---------------------------------------- */

let _drawLetters = (ctx) => {
    const adj = 0;

    for(let i in chars) {
        ctx.putImageData(chars[i].imgData, i * dims.stripeW, 0);
    }

    ctx.globalCompositeOperation = 'destination-over';

    for(let i in chars) {
        if (chars[i].background) {
            let searchResult = searchCombi(chars[i].foreground, chars[i].background);
            if (!searchResult) return;
        
            ctx.fillStyle = searchResult.bgCode;
            ctx.fillRect(i * dims.stripeW, 0 + adj, dims.stripeW - adj, dims.letterH);
        }
    };

    ctx.globalCompositeOperation = 'source-over';
}

/* ----------------------------------------
    Generate letters + colors
---------------------------------------- */

let _generateLetters = (ctx, textCtx, index, char, color, init) => {
    if (!char || !color.second.color) return;

    if (chars[index]) {
        // only calculate if color changes or if the text changes)
        if (color.first.color == chars[index].background && color.second.color == chars[index].foreground || !init) {
            return;
        }
    }

    let image = new Image();

    // if char exists in images
    if (scarfAlphabet.normal[char.toLowerCase()]) {
        image.src = scarfAlphabet.normal[char.toLowerCase()];
    } else {
        image.src = scarfAlphabet.normal[' '];
    }

    image.onload = function() {
        let searchResult = searchCombi(color.second.color, color.first.color);
        if (!searchResult) return;

        textCtx.clearRect(0,0,300,300)
        textCtx.fillStyle = searchResult.fgCode;
        textCtx.drawImage(image, 0, 0);
        textCtx.globalCompositeOperation = 'source-in';
        textCtx.fillRect(0,0,dims.stripeW,dims.letterH);
        textCtx.globalCompositeOperation = 'source-over';
        let charImgData = textCtx.getImageData(0, 0, dims.stripeW, dims.letterH);
        chars[index] = {
            foreground: color.second.color,
            background: color.first.color,
            imgData: charImgData,
            char: char
        }

        _drawLetters(ctx);
    }
}

/* ----------------------------------------
    Draw Stripe
---------------------------------------- */

const _drawStripe = (ctx, colorChoices, stripeNo, width, height) => {
    if (!colorChoices) return;
    
    ctx.save();
    ctx.translate(width * stripeNo, 0);
    
    if (colorChoices.second.color && colorChoices.first.color) {       
        let searchResult = searchCombi(colorChoices.second.color, colorChoices.first.color);

        if (!searchResult) return;

        //mix
        ctx.fillStyle = searchResult.mix;
            
        ctx.fillRect(0, dims.letterH,width, height - dims.letterH)
    }

    ctx.restore();
}

/* ----------------------------------------
    Draw all
---------------------------------------- */

const scarfBmp = (ctx, canvas, shapes, height, colorChoices, imprint, init) => {
    shapes.forEach((shape, j) => {
        _generateLetters(ctx, bmpTextCtx, j, imprint[j], { first: colorChoices[j].first, second: colorChoices[j].second }, init);
        _drawLetters(ctx);
        _drawStripe(ctx, colorChoices[j], j, dims.stripeW, height);
    })

    let imageData = canvas.getContext("2d").getImageData(0, 0, canvas.clientWidth, canvas.clientHeight);
    return imageData;
};

/* ----------------------------------------
    Get scarf bmps
---------------------------------------- */

const imageDataToPng = (imagedata) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.classList.add('imageDataCanvas')
    canvas.width = imagedata.width;
    canvas.height = imagedata.height;
    ctx.putImageData(imagedata, 0, 0);
    return canvas.toDataURL("image/png");
}

const getScarfBmps = (ctx, height) => {
    let pushBack = [10,10,10,10,2];
    let currentX = 0;
    let results = [];

    for (let i = 0; i < pushBack.length; i++) {
        currentX += pushBack[i]
        let imgData = ctx.getImageData(dims.stripeW * dims.stripeQuant - dims.stripeW * currentX, 0, dims.stripeW * pushBack[i], height);
        results.push(imageDataToPng(imgData))
    }
    
    return results;
}

/* ----------------------------------------
    Init
---------------------------------------- */

const initBmp = (colorChoices, height) => {
    // main bmp canvas
    const bmpCanvas = document.createElement('canvas'); 
    chars =  {}
    bmpCanvas.id = "bmpCanvas"; 
    bmpCanvas.classList.add('bmpCanvas');
    bmpCanvas.width = dims.stripeW * dims.stripeQuant;
    bmpCanvas.height = height; 
    document.body.appendChild(bmpCanvas);

    // render letters canvas
    bmpTextCanvas = document.createElement('canvas'); 
    bmpTextCtx = bmpTextCanvas.getContext("2d")
    bmpTextCanvas.id = "bmpTextCanvas"; 
    bmpTextCanvas.classList.add("bmpTextCanvas");
    bmpTextCanvas.width = dims.stripeW;
    bmpTextCanvas.height = dims.letterH; 
    document.body.appendChild(bmpTextCanvas);
    return bmpCanvas;
}

/* ----------------------------------------
    Export Module
---------------------------------------- */

export {
    getScarfBmps,
    scarfBmp,
    initBmp
};
